$light-gray: #f0f0f0;
$lightish-gray: #b1b1b1;
$lighter-gray: #f5f5f5;
$input-border-gray: #a08bcb;
$efnine-gray: #f9f9f9;
$efeight-gray: #f3f3f3;
$mid-gray: #d8d8d8;
$dk-gray: #4b5751;
$hr-gray: #959595;
$pwd-bg-gray: #979797;
$pwd-fg-gray: #000000;
$black: #000000;
$white: #ffffff;
$tile-bg: #bcbdbf;
$ninesee: #9c9c9c;
$radio-button-selector-border: #605d75;
$add-new-prescriber-color: #4b5751;
$table-header-column-border-color: #f8f8f8;
$table-column-border-color: #d1d4d3;
//Fonts
$default-font-size: 14px;
$paragraph-font-size: 14px;
$secondary-paragraph-font-size: 20px;
$klavika-bold:
  Co Text Bold,
  sans-serif;
$custom-font-bold: "Metropolis", sans-serif;
$custom-font-medium: "Metropolis", sans-serif;
$custom-font-regular: "Metropolis", sans-serif;
$fs-albert:
  FS Albert,
  sans-serif;
$secondary-font-bold: "Metropolis", sans-serif;
$secondary-font: "Metropolis", sans-serif;

//Colors

$primary-color: #7fb822;
$secondary-color: #4b5751;
$tertiary-color: #f67e7d;
$quaternary-color: #8bd3e6;

$table-odd-bg-color: transparent;
$every-other-table-row-odd-green: #eaeaea;

$header-navigation: $white;
$header-navigation-hover: #e8e8e8;
$forms-and-resources: $white;
$forms-and-resources-hover: $primary-color;
$header-background-gray: $secondary-color;
$mobile-nav-top-border-gray: #5b5d5f;
$mobile-nav-link-color: $white;
$mobile-nav-background-color: $secondary-color;
$site-dk-gray: #202020;
$text-red: $primary-color;
$button-bg-color: $secondary-color;
$button-txt-color: $white;
$button-txt-hover-color: $white;
$button-bg-hover-color: $primary-color;
$button-font-size: 20px;
$button-border-radius: 8px;
$custom-select-input-field-color: #605d75;
$custom-panel-border-color: #7fb822;
$custom-panel-label-weight: 700;
$custom-panel-boder-radius: 0px;
$custom-panel-border-thickness: 0px;
$required-text-color: $tertiary-color;
$required-font-size: 16px;
$main-padding-top: 0;
$main-padding-bottom: 10rem;
$main-margin-top: 0;
$main-margin-bottom: 0;
$alert-padding: 30px;
$alert-border-top-right-radius: 0px;
$alert-border-bottom-right-radius: 0px;
$alert-close-icon-color: $white;
$alert-headline-color: $white;
$alert-body-text-color: $white;
$footer-background-color: #1794ce;
$input-placeholder-color: #000;
$two-col-modal-width: 1280px;
$single-col-modal-width: 905px;
$single-col-modal-width-snaller: 620px;
$left-col-modal-nav-active: #f69637;
$voucher-provider-header-bg-color: #78817c;

$modal-border-radius: 0px;
$larger-border-radius: 0px;
$table-border-bottom-dark: #bbbdbf;

$bottom-border-darker: $custom-panel-border-color;
$white-container-border-color: #4b5751;

$table-header-bg: #d6d6d6;

$border-top-alert-red: #e90649;
$border-top-alert-yellow: #fdb122;
$border-top-alert-green: #7bc770;
$border-top-alert-gray: #d1d4d3;

$error-msg-font-size: 15px;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.registration-dynamic-terms-height {
  max-height: 250px;
  overflow: auto;
}

.registration-wrapper-height {
  max-height: 700px;
  min-height: 632px;
  // height: 700px;
}

.registration-sm {
  max-width: 517px !important;
}

.forgot-password-link {
  color: inherit;
}

.shared-profile-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.shared-profile-links .btn {
  padding: 13px 22px;
  width: auto;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 3px;
  line-height: 1;
  font-weight: 400;
}

.shared-profile-links .shared-btn-reversed.btn-blue {
  background-color: white;
  color: #003ea5;
  border-color: #003ea5 !important;
}

.react-multi-carousel-list,
.react-multi-carousel-track {
  width: 100%;
}

#__next {
  padding: 20px;
}
// .slider-item {
//   padding: 20px 0 20px 20px;
// }

.carousel-container {
  width: 100%;
}

@font-face {
  font-family: "Metropolis";
  src:
    url("assets/fonts/Metropolis-Light.woff2") format("woff2"),
    url("assets/fonts/Metropolis-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src:
    url("assets/fonts/Metropolis-Regular.woff2") format("woff2"),
    url("assets/fonts/Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src:
    url("assets/fonts/Metropolis-Bold.woff2") format("woff2"),
    url("assets/fonts/Metropolis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.ml-1 {
  margin-left: 1rem;
}

.manageFavorite td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-bottom-width: 1px;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.nr-wrap {
  padding: 30px 46px 200px;
}

.no-results {
  padding: 70px 20px;
  width: 100%;
  background-color: white;
}

.no-results h1 {
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

.no-results p {
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.regular-looking-dropdown__control {
  border: 1px solid #7c7c7c !important;
}
.react-multi-email {
  border: 1px solid #7c7c7c;
  // height: 41px;
  margin: 0px;
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.react-multi-email input[type="text"] {
  height: 38px;
  line-height: 100%;
  font-size: 14px;
  padding: 14px !important;
  font-weight: 300;
  color: #202020;
}
.react-multi-email span {
  height: 38px;
  line-height: 100%;
  font-size: 14px;
  // padding: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  // margin-left: 15px;
}
.react-multi-email [data-tag] {
  font-size: 14px;
  font-weight: 300;
  background-color: #d5e1f8;
  color: #002870;
  margin-right: 6px;
  padding: 0px 10px;
  position: relative;
  top: 0px;
}
.regular-looking-dropdown__input input[type="text"],
.regular-looking-dropdown__input input[type="password"],
.regular-looking-dropdown__input .form-select,
.regular-looking-dropdown__input .search-input {
  height: 30px !important;
}

.underlined-dropdown__input input[type="text"],
.underlined-dropdown__input input[type="password"],
.underlined-dropdown__input .form-select,
.underlined-dropdown__input .search-input {
  height: 30px !important;
}

.underlined-dropdown__control {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #7c7c7c !important;
  border-radius: 0px !important;
}

.underlined-dropdown__placeholder {
  color: black !important;
}

.main-bg {
  background-color: #f5f6f8;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .main-bg {
    height: 100vh;
  }
}

body {
  min-height: 100vh;
  font-family: "Metropolis", san-serif;
  overflow: -moz-scrollbars-none;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: 0.02rem;
  color: #373a3c;
}

h1 {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
}

h2 {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 18px;
}

.page-title h2 {
  margin-bottom: 4px;
  color: #373a3c;
}

.page-title p {
  color: #373a3c;
}

h3 {
  font-size: 24px;
  line-height: 28px;
}

div,
span,
li,
p {
  font-weight: normal;
  font-size: 16px;
}

p,
p a {
  color: #202020;
  letter-spacing: 0.04rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04rem;
}

.btn {
  letter-spacing: 0.04rem;
}

.location.form-select {
  background-size: 10px;
  background-repeat: no-repeat;
  padding: 2px 12px 2px 6px;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
}

header {
  height: 69px;
  padding: 14.5px 40px 14.5px 30px;
  position: sticky;
  top: 0;
  z-index: 98;
  background-color: $white;
  border-bottom: 1px solid #eaeaea;
}

@media screen and (max-width: 767px) {
  header {
    height: auto;
  }
}

.portal {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.scroll-container {
  height: calc(100vh - 69px);
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .scroll-container {
    height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  .portal {
    height: auto;
    overflow: scroll;
  }
}

.portal-content {
  height: 100vh;
}

.portal-content-white {
  position: relative;
  height: calc(100vh - 111px);
  overflow-y: auto;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .portal-content-white {
    height: 100vh;
  }
}

.portal-content .left-col {
  height: 100vh;
  padding: 34px 28px 34px 0;
  -webkit-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.13);
  position: sticky;
  left: 0;
  z-index: 20;
  min-height: calc(100vh - 84px);
}

@media screen and (max-width: 991px) {
  .portal-content .left-col {
    min-height: auto;
    height: auto;
  }
}

.portal-content .right-col a.inactive {
  opacity: 0.2;
}

.portal-content aside h2 {
  padding-left: 28px;
  margin-bottom: 10px;
}

.portal-content aside a.category-link {
  padding-left: 28px;
}

.portal-content aside a.category-link.active {
  background-image: url("assets/img/aside-rectangle.png");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
}

.portal-content aside a.category-link.active .text {
  font-weight: 700;
}

.portal-content aside a.category-link.active .pill {
  color: white;
  background-color: #003ea5;
}

.portal-content main div.cp {
  padding: 36px 46px;
}

.portal-content.my-account main {
  background-color: #fff;
}

.portal-content.my-account main .breadcrumbs {
  padding: 40px 46px 0px;
}

.portal-content.my-account .right-col {
  padding-right: 0px;
}

@media screen and (min-width: 1200px) {
  .portal-content.my-account .right-col {
    padding-right: 100px;
  }
}

.portal-content.my-account .right-col .btn {
  height: 50px;
}

.portal-content.my-account .right-col p {
  line-height: 22px;
}

hr {
  height: 2px;
  width: 100%;
  margin-top: 0px;
  border: 1px solid #dfdfdf;
}

.padding1rem {
  padding: 0.5rem 0.5rem 0rem 0rem !important;
}

.toastDownloadsContainer {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box;
}
.popper {
  position: relative;
  width: fit-content;
  width: 320px !important;
  height: 65px !important;
  min-height: 65px !important;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding-right: 20px;
  box-sizing: border-box;
}
.bxshw {
  box-shadow: 0px 6px 3px 0px #0000001a;
}
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.pophd {
  width: 100% !important;
  background-color: #0d2658;
  padding: 0.5rem !important;
}
.height40 {
  height: 40px !important;
}
.padding0 {
  padding: 0px !important;
}
.clrwhte {
  color: #ffffff !important;
}
.nounderln {
  text-decoration: none;
}
.width25 {
  width: 25px !important;
}
.fltr {
  float: right !important;
}
.width30 {
  width: 30px !important;
}
.paddingl20 {
  padding-left: 20px !important;
}
.bckclrdb {
  background-color: #0d2658;
}
.fntsize12 {
  font-size: 12px !important;
}
.width15 {
  width: 15px !important;
}
.paddingT5p {
  padding-top: 5% !important;
}
.width12 {
  width: 12px !important;
}
.popbod {
  padding: 10px 5px !important;
}
.width70 {
  width: 70px !important;
}
.bordrad8px {
  border-radius: 8px !important;
}
.height25 {
  height: 25px !important;
  line-height: normal !important;
}
.paddingR3px {
  padding-right: 3px !important;
}

.hide-it {
  display: none;
}

.category-nav {
  padding: 0px;
  list-style: none;
}

.category-nav li {
  margin-bottom: 7px;
}

.category-nav li a {
  padding-top: 12px;
  padding-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 100%;
  text-decoration: none;
  color: #202020;
  font-size: 16px;
}

.category-nav li a .text {
  padding-right: 10px;
}

.category-nav li a .pill {
  font-size: 10px;
  height: 22px;
  width: 39px;
  border: 1px solid #4f4f4f;
  border-radius: 11px;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-nav li a:hover .text {
  color: #003ea5;
}

.content-card {
  background-color: #fff;
  padding: 20px 16px;
  width: 100%;
  // max-width: 317px;
  margin: 0 0 30px 0;
  position: relative;
}

.content-card .image-container {
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  height: 246px;
  overflow: hidden;
}

.content-card .text-container .card-title {
  font-size: 16px;
  font-weight: 700;
}

.content-card .text-container .card-title a.content-card-link {
  text-decoration: none;
  color: inherit;
}

.content-card .text-container .card-sub-title {
  font-size: 14px;
  font-weight: 300;
}

.cards {
  overflow: hidden;
  padding-left: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cards .content-card {
  width: 214px;
  padding: 0px;
  margin: 0 30px 30px 0;
}

.cards .content-card .image-container {
  margin: 0 auto;
  background-color: #e8eaeb;
}

.cards .content-card .content-card-footer {
  padding: 6px 10px 10px;
}

.cards .content-card .content-card-footer .file-name {
  font-size: 10px;
  color: #414141;
  height: 30px;
  overflow: auto;
}

.cards .content-card .content-card-footer ul {
  list-style: none;
  padding: 0;
  margin: 5px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cards .content-card .content-card-footer ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cards .content-card .content-card-footer ul li a {
  padding: 0px 8px 0px;
  display: block;
}

.cards .content-card .content-card-footer .content-card-separator {
  height: 14px;
  width: 1px;
  border: 1px solid #979797;
  opacity: 0.19;
}

.recently-added .ra-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 46px;
}

.recently-added .ra-header h3 {
  margin-bottom: 0px;
  font-size: 18px;
}

.recently-added .ra-header .view-all {
  font-size: 18px;
  text-decoration: none;
  color: #202020;
}

.registration-bg {
  background-image: url("assets/img/logInBackground.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showHide {
  width: 50px !important;
  height: 100%;
  font-size: 18px !important;
  padding: 7px 9px !important;
}

.registration-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.registration {
  max-width: 1000px;
  width: 100%;
  border-radius: 11px;
  margin: 10px;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.registration .col-left {
  background-color: #003ea5;
  border-radius: 11px 0 0 11px;
  padding: 50px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.registration .col-left p,
.registration .col-left h2 {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .registration .col-left {
    border-radius: 11px 11px 0 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.registration .col-right {
  background-color: #fff;
  border-radius: 0 11px 11px 0;
  padding: 50px 70px;
}

@media screen and (max-width: 767px) {
  .registration .col-right {
    border-radius: 0 0 11px 11px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 991px) {
  .registration .col-left,
  .registration .col-right {
    padding: 40px 30px;
  }
}

.registration .single-col {
  border-radius: 11px;
  background-color: #fff;
  padding: 50px 50px;
  // height: 632px;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .registration .single-col {
    padding: 40px 30px;
  }
}

label {
  height: 19px;
  color: #202020;
  font-family: "Metropolis", san-serif;
  font-size: 16px;
  // font-size: 0.6vw;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.form-check {
  padding-left: 28px;
}

.form-check input {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  margin: 5px 10px auto 2px;
  border: 1px solid #7c7c7c;
}

.form-check label {
  line-height: 15px;
  font-size: 13px;
  font-weight: normal;
}

input[type="text"],
input[type="password"],
.form-select,
.search-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 43px;
  border: 1px solid #7c7c7c;
  border-radius: 3px;
  line-height: 100%;
  vertical-align: middle;
  color: #000;
}

.underlined-dropdown,
.underlined-dropdown__control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 43px;
  border: 1px solid #7c7c7c;
  border-radius: 3px;
  line-height: 100%;
  vertical-align: middle;
  color: #000;
  border-top: none;
  border-right: none;
  border-left: none;
  margin-bottom: 0px;
  border-radius: 0px;
  font-size: 15px;
  padding: 4px 0px;
}

.underlined-dropdown .underlined-dropdown__placeholder,
.underlined-dropdown__control .underlined-dropdown__placeholder {
  font-size: 15px;
  line-height: 1;
  margin: 0;
}

.underlined-dropdown .left-dropdown-input,
.underlined-dropdown .underlined-dropdown__value-container,
.underlined-dropdown__control .left-dropdown-input,
.underlined-dropdown__control .underlined-dropdown__value-container {
  height: 40px;
  font-size: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
}

.underlined-dropdown .right-dropdown-input,
.underlined-dropdown .underlined-dropdown__indicators,
.underlined-dropdown__control .right-dropdown-input,
.underlined-dropdown__control .underlined-dropdown__indicators {
  height: 40px;
}

.underlined-dropdown .indicator-separator,
.underlined-dropdown .underlined-dropdown__indicator-separator,
.underlined-dropdown__control .indicator-separator,
.underlined-dropdown__control .underlined-dropdown__indicator-separator {
  display: none;
}

.underlined-dropdown .indicator-container,
.underlined-dropdown .underlined-dropdown__indicator,
.underlined-dropdown__control .indicator-container,
.underlined-dropdown__control .underlined-dropdown__indicator {
  color: #7c7c7c;
}

.regular-dropdown,
.regular-looking-dropdown__control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #7c7c7c;
  height: 43px;
  line-height: 1;
  color: #000;
  border-radius: 3px;
  vertical-align: middle;
  color: #000;
}

.regular-dropdown .regular-looking-dropdown__placeholder,
.regular-looking-dropdown__control .regular-looking-dropdown__placeholder {
  font-size: 15px;
  line-height: 0;
  margin: 0;
}

.regular-dropdown .left-dropdown-input,
.regular-dropdown .regular-looking-dropdown__value-container,
.regular-looking-dropdown__control .left-dropdown-input,
.regular-looking-dropdown__control .regular-looking-dropdown__value-container {
  height: 100%;
  font-size: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
}

.regular-dropdown .right-dropdown-input,
.regular-dropdown .regular-looking-dropdown__indicators,
.regular-looking-dropdown__control .right-dropdown-input,
.regular-looking-dropdown__control .regular-looking-dropdown__indicators {
  height: 100%;
}

.regular-dropdown .indicator-separator,
.regular-dropdown .regular-looking-dropdown__indicator-separator,
.regular-looking-dropdown__control .indicator-separator,
.regular-looking-dropdown__control
  .regular-looking-dropdown__indicator-separator {
  display: none;
}

.regular-dropdown .indicator-container,
.regular-dropdown .regular-looking-dropdown__indicator,
.regular-looking-dropdown__control .indicator-container,
.regular-looking-dropdown__control .regular-looking-dropdown__indicator {
  color: #7c7c7c;
}

.regular-dropdown:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-color: #86b7fe;
  outline: 0;
}

//.react-multi-email {
//  border: 1px solid #7C7C7C;
//  height: 41px;
//  margin: 0px;
//  padding: 0px;
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-box-pack: start;
//  -ms-flex-pack: start;
//  justify-content: flex-start;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//}
//
//.react-multi-email input[type=text] {
//  height: 38px;
//  line-height: 100%;
//  font-size: 14px;
//  padding: 0px !important;
//  font-weight: 300;
//  color: #202020;
//}
//
//.react-multi-email span {
//  height: 38px;
//  line-height: 100%;
//  font-size: 14px;
//  padding: 0px !important;
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  margin-left: 15px;
//}
//
//.react-multi-email [data-tag] {
//  font-size: 14px;
//  font-weight: 300;
//  background-color: #D5E1F8;
//  color: #002870;
//  margin-right: 6px;
//  position: relative;
//  top: 1px;
//}

.search {
  width: 100%;
  max-width: 410px;
}

input.search-input {
  height: 39px;
  background-image: url("assets/img/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;
  border-width: 2px;
  max-width: 410px;
  width: 100%;
  padding-left: 36px;
}

input.search-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #202020 !important;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

input.search-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #202020 !important;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

input.search-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #202020 !important;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

input.search-input:-moz-placeholder {
  /* Firefox 18- */
  color: #202020;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

input.search-input::-ms-input-placeholder {
  color: #202020 !important;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

input.search-input::placeholder {
  color: #202020 !important;
  font-weight: 700;
  font-size: 15px;
  text-transform: none;
}

.account-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .account-links {
    margin-top: 20px;
  }
}

.btn.btn-blue {
  background-color: #003ea5;
  color: white;
}

.btn.btn-reversed {
  border: 1px solid #202020;
  border-radius: 3px;
  height: 43px;
  color: #202020;
}

.btn.btn-red {
  background-color: #f7665e;
  color: white;
  height: 43px;
}

.btn.btn-turq {
  background-color: #0fb1ba;
  color: white;
}

.btn {
  padding: 13px 20px;
  font-weight: 700;
  letter-spacing: 3%;
  width: 100%;
  border-radius: 3px;
  font-size: 16px;
}

@media screen and (max-width: 991px) {
  .btn {
    max-width: 428px;
  }
}

.progress-buttons,
.my-account-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.progress-buttons .btn-reversed.btn-blue,
.my-account-buttons .btn-reversed.btn-blue {
  background-color: white;
  color: #003ea5;
  border-color: #003ea5;
}

.progress-buttons .btn,
.my-account-buttons .btn {
  width: auto;
  margin-right: 10px;
  border-radius: 5px;
  line-height: 1;
}

.progress-buttons .btn:last-child,
.my-account-buttons .btn:last-child {
  margin-right: 0;
}

.my-account-buttons {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.fs-13 {
  font-size: 13px !important;
}

.or-section {
  height: 30px;
}

.or-section .or-line {
  background-color: #d2d2d2;
  height: 2px;
  width: 100%;
}

.or-section .or-text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  position: absolute;
  z-index: 20;
  background-color: white;
  padding: 10px;
  color: #7a7a7a;
}

.the-steps {
  height: 30px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .the-steps {
    margin-top: 30px;
  }
}

.the-steps .or-line {
  background-color: #003ea5;
  opacity: 0.16;
  height: 2px;
  width: 80%;
  position: absolute;
  top: -10%;
}

.the-steps .step-children {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 90;
}

.the-steps .step-children .step-child {
  width: 74px;
  margin-right: 20px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.the-steps .step-children .step-child .step-circle {
  border-radius: 50%;
  border: 1px solid #003ea5;
  width: 38px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  font-size: 16px;
  color: #003ea5;
  font-weight: bold;
  line-height: 100%;
}

.the-steps .step-children .step-child .step-text {
  font-size: 10px;
  line-height: 120%;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.the-steps .step-children .step-child.active .step-circle {
  background-color: #003ea5;
  color: white;
}

.the-steps .step-children .step-child.complete .step-circle {
  background-color: #0d2658;
  color: white;
}

.the-steps .step-children .step-child:last-child {
  margin-right: 0;
}

.terms-and-conditions {
  max-height: 212px;
  overflow-y: scroll;
}

.terms-and-conditions-agreement strong {
  font-size: 14px;
}

.filter {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 42px;
  background-color: #0d2658;
  padding: 7.5px 46px;
}

@media screen and (max-width: 767px) {
  .filter {
    height: auto;
  }
}

.filter .dropdown-toggle::after {
  display: none;
}

.filter a {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  padding: 5px 5px 5px 0px;
  margin-right: 14px;
}

.filter a:last-child {
  margin-right: 0px;
}

.filter button {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ffffff;
  border-radius: 3px;
  height: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 90% 7px;
  padding: 0px 30px 0px 12px;
  line-height: 100%;
  background-color: #0d2658;
}

.filter button.btn.btn-filter {
  background-image: url("assets/img/icon-filter-btn.svg");
}

.filter button.btn.btn-filter.active {
  color: #0d2658;
  background-color: white;
  background-image: url("assets/img/icon-filter-btn-blue.svg");
}

.filter button.btn.btn-name {
  background-image: url("assets/img/icon-name.svg");
  background-size: 5px;
  background-position: 86% center;
}

.filter button.btn.btn-order {
  background-image: url("assets/img/icon-order.svg");
  background-position: center;
}

.filter button.btn.btn-order-desc {
  background-image: url("assets/img/icon-order-desc.svg");
  background-position: center;
}

.filter button:active,
.filter button:focus,
.filter button:hover {
  background-color: #0d2658;
  color: #fff;
  border: 1px solid #ffffff;
}

.filter .spacer {
  height: 22px;
  width: 1px;
  border: 1px solid #ffffff;
  opacity: 0.37;
  margin: 0px 20px;
}

.filter .dropdown-menu {
  top: 14px !important;
  border-radius: 3px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  z-index: 10;
}

.filter .dropdown-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #202020;
  padding: 6px 18px;
}

.filters-container .form-select {
  border-top: none;
  border-right: none;
  border-left: none;
  margin-bottom: 20px;
  border-radius: 0px;
  font-size: 15px;
  padding: 4px 15px;
}

.rmsc.multi-select .dropdown-container {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #7c7c7c;
  border-radius: 0px;
  margin-bottom: 20px;
  font-size: 15px;
  padding: 4px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 43px;
  line-height: 100%;
  vertical-align: middle;
  color: #000;
}

.rmsc2.multi-select .dropdown-container {
  border-bottom: 3px solid #003ea5;
}

.rmsc.multi-select .dropdown-container .gray {
  color: black !important;
}

.rmsc.multi-select .dropdown-container .dropdown-content .panel-content {
  padding: 10px;
}

.rmsc.multi-select
  .dropdown-container
  .dropdown-content
  .panel-content
  .select-panel
  .search
  input[type="text"] {
  background-image: url("assets/img/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 16px;
  padding-right: 36px;
  height: 30px;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.rmsc.multi-select .dropdown-container label {
  height: auto;
  width: auto;
}

.rmsc.multi-select .dropdown-container .select-item:focus,
.rmsc.multi-select .dropdown-container .rmsc .select-item:hover {
  background: none;
}

.rmsc.multi-select .dropdown-container .select-item input[type="checkbox"] {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  margin: 0 10px 0 0;
}

.like-toggle .circle-count {
  width: 23px;
  height: 23px;
  background-color: #003ea5;
  font-size: 9px;
  color: white;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 100%;
  margin: 0px;
  padding: 0px;
  border: 2px solid white;
}

.region .dropdown .btn-region {
  background-color: white;
  padding: 0px 20px 0px;
  margin-left: 2px;
}

.region .dropdown .btn-region:focus + .btn,
.region .dropdown .btn-region:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.region .dropdown .dropdown-menu {
  top: 30px !important;
  width: 354px;
  -webkit-transform: translate3d(-263px, 28px, 0px) !important;
  transform: translate3d(-263px, 28px, 0px) !important;
  padding: 20px;
  inset: auto !important;
}

@media screen and (max-width: 767px) {
  .region .dropdown .dropdown-menu {
    -webkit-transform: translate3d(-42%, 28px, 0px) !important;
    transform: translate3d(-42%, 28px, 0px) !important;
  }
}

.region .dropdown .dropdown-menuh4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.region .dropdown .dropdown-menu.form-select {
  margin-bottom: 10px;
  height: 39px;
  font-weight: bold;
  line-height: 100%;
}

.region .dropdown .dropdown-menup {
  font-size: 12px;
  color: #686868;
  margin-bottom: 10px;
  line-height: 140%;
}

.region .dropdown .dropdown-menubutton {
  height: 39px;
  padding: 7px 20px;
}

button#headerAccountRegion {
  padding: 3px;
  background-color: white;
  padding-right: 15px;
  margin-left: 5px;
  border: 0;
  color: #212529;
}

.region .regular-looking-dropdown__single-value {
  font-weight: 600;
}

.filter-list {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .filter-list {
    overflow-x: scroll;
  }
}

button.btn-filter-list {
  height: 31px;
  width: auto;
  color: white;
  padding: 0px 12px 0px 10px;
  border-radius: 3px;
  background-color: #003ea5;
  display: block;
  margin-right: 10px;
  font-size: 14px;
  line-height: 100%;
}

button.btn-filter-list .clear-x {
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 2px;
  top: -1px;
  position: relative;
}

button.btn-filter-list:hover {
  color: #fff;
  background-color: rgb(13, 110, 253);
}

button.btn-filter-list.clear-all {
  padding: 0px 10px;
  background-color: rgba(0, 62, 165, 0.4);
}

.txt-white {
  color: white;
}

.breadcrumbs {
  padding: 30px 46px 0px;
  font-size: 15px;
  font-weight: bold;
}

.breadcrumbs .opacity-40 {
  opacity: 0.4;
  font-weight: bold;
  font-size: 15px;
}

.cards .row {
  width: 100%;
}

.catalog-page main div.cp {
  padding: 30px 46px;
}

.catalog-page main {
  position: relative;
}

.content-card {
  position: relative;
  border-radius: 3px;
  // width: 222px;
}

.content-card.selected {
  border: 4px solid #003ea5;
  border-radius: 7px;
}

.asset-tag-container {
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.asset-tag {
  width: 50px;
  background-color: #003ea5;
  color: white;
  font-size: 12px;
  padding: 3px 10px;
  margin-bottom: 5px;
  text-align: center;
}

.new-asset {
  background-color: #15a102;
}

.keyboard-button {
  height: 41px;
  width: 41px;
  background-color: #003ea5;
  -webkit-box-shadow: 1px 3px 4px 0 rgba(0, 62, 165, 0.28);
  box-shadow: 1px 3px 4px 0 rgba(0, 62, 165, 0.28);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  position: fixed;
  bottom: 21px;
  right: 21px;
  z-index: 300;
}

.assetDetailsModal .sidenav {
  position: relative;
  padding: 12px 32px 32px;
  width: 435px;
}

.assetDetailsModal .asset-content {
  margin-right: 0px;
}

.img-fluid {
  width: auto;
  height: auto;
}

.assetDetailsModal .asset-content .img-fluid {
  //max-width: 550px;
  //max-height: 550px;
  // min-width: 50%;
  max-width: 90%;
  max-height: 90%;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  //.assetDetailsModal .sidenav {
  //  width: 380px;
  //  position: absolute;
  //  padding: 10px 20px 20px 20px;
  //  z-index: 1000;
  //}
  .assetDetailsModal .asset-content .img-fluid {
    height: auto;
    width: auto;
  }
}

.filters-collapse {
  background: white;
  width: 100%;
  position: relative;
  z-index: 4;
}

@media screen and (max-width: 767px) {
  .filters-container {
    height: 100vh;
  }
}

.filters-collapse .filters-container {
  padding: 20px 46px 30px;
}

.helpModal .modal-dialog {
  max-width: 607px;
}

.helpModal .modal-header {
  padding-left: 37px;
  padding-right: 27px;
  padding-top: 27px;
  padding-bottom: 14px;
}

.helpModal .modal-body {
  padding-top: 30px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 90px;
}

@media screen and (max-width: 600px) {
  .helpModal .modal-body {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.helpModal h6,
.helpModal p {
  font-size: 14px;
}

.helpModal p.small {
  font-size: 12px;
  font-weight: 300px;
  line-height: 18px;
}

.passwordModal {
  padding: 30px;
}

.passwordModal .modal-body {
  height: auto;
  width: auto;
  padding: 37px 27px;
}

.retry-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .retry-button-container {
    justify-content: center;
  }
  .retry-button {
    width: 428px !important;
  }
}

.shareModal .modal-dialog {
  max-width: 607px;
}

.shareModal .modal-header {
  padding-left: 37px;
  padding-right: 27px;
  padding-top: 27px;
  padding-bottom: 14px;
}

.shareModal p.small {
  font-size: 12px;
  font-weight: 300px;
  line-height: 18px;
}

.shareModal input {
  line-height: 100%;
}

.shareModal .modal-body {
  padding-top: 30px;
  padding-left: 36px;
  padding-right: 46px;
  padding-bottom: 70px;
}

@media screen and (max-width: 600px) {
  .shareModal .modal-body {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.shareModal .shareLink input {
  border: 0;
  background-color: #e6f4ea;
  color: #188038;
  font-size: 14px;
}

.shareModal .shareLink button {
  background: #d5e1f8;
  color: #003ea5;
}

.downloadModal .modal-dialog {
  max-width: 607px;
}

.downloadModal .modal-title {
  line-height: 100%;
}

.downloadModal .modal-header {
  padding-left: 37px;
  padding-right: 27px;
  padding-top: 18px;
  padding-bottom: 15px;
}

.downloadModal .modal-body {
  padding-left: 40px;
  padding-right: 40px;
}

.downloadModal .form-select {
  min-width: 242px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .downloadModal .form-select {
    min-width: auto;
  }
}

@media screen and (max-width: 600px) {
  .downloadModal .form-control {
    min-width: auto;
  }
}

.downloadModal .body-header .file-type {
  margin-left: 15px;
}

.downloadModal .body-header > p {
  margin-bottom: 20px;
}

.downloadModal .body-header p,
.downloadModal .body-header a {
  font-size: 12px;
  line-height: 100%;
}

.downloadModal .body-header a {
  font-weight: bold;
  text-decoration: none;
}

.downloadModal .body-header a:hover {
  cursor: pointer;
}

.downloadModal .table {
  padding: 0px;
}

.downloadModal .table td:first-child {
  padding-left: 0;
  width: 40%;
}

.downloadModal .table td:last-child {
  padding-right: 0;
}

.downloadModal .table .bg-w,
.downloadModal .table .bg-h {
  position: relative;
  background-position: 92% center;
  background-repeat: no-repeat;
  padding-right: 36px;
}

.downloadModal .table .bg-w {
  background-image: url("assets/img/icon-w.svg");
  background-size: 16px;
}

.downloadModal .table .bg-h {
  background-image: url("assets/img/icon-h.svg");
  background-size: 14px;
}

.downloadModal .img-area {
  height: 60vh;
  max-height: 380px;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.downloadModal .img-area img {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: block;
  //min-width: 50%;
}

.downloadModal button.btn {
  height: 50px !important;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px !important;
  line-height: 100%;
  width: 100%;
  max-width: 100%;
}

.favoritesModal .modal-dialog {
  max-width: 607px;
}

.favoritesModal .modal-title {
  line-height: 100%;
}

.favoritesModal .modal-header {
  padding-left: 37px;
  padding-right: 27px;
  padding-top: 18px;
  padding-bottom: 15px;
}

.favoritesModal .modal-body {
  padding-top: 5px;
  padding-left: 40px;
  padding-right: 40px;
}

.favoritesModal .form-select {
  min-width: 242px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .favoritesModal .form-select {
    min-width: auto;
  }
}

@media screen and (max-width: 600px) {
  .favoritesModal .form-control {
    min-width: auto;
  }
}

.favoritesModal .body-header .file-type {
  margin-left: 15px;
}

.favoritesModal .body-header > p {
  margin-bottom: 20px;
}

.favoritesModal .body-header p,
.favoritesModal .body-header a {
  font-size: 12px;
  line-height: 100%;
}

.favoritesModal .body-header a {
  font-weight: bold;
  text-decoration: none;
}

.favoritesModal .body-header a:hover {
  cursor: pointer;
}

.favoritesModal .body-header h6 {
  line-height: 1;
  top: 2px;
  position: relative;
}

.favoritesModal .table {
  padding: 0px;
}

.favoritesModal .table td:first-child {
  padding-left: 0;
}

.favoritesModal .table td:last-child {
  padding-right: 0;
}

.favoritesModal .table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom-width: 1px;
}

.favoritesModal .table td label {
  font-size: 16px;
  font-weight: 300;
  position: relative;
  top: 2px;
}

.favoritesModal .table td input.form-check-input {
  -webkit-transform: scale(160%);
  -ms-transform: scale(160%);
  transform: scale(160%);
  margin-right: 20px;
  position: relative;
  left: 1px;
  border: 1px solid #003ea5;
}

.favoritesModal .table td .form-check-input:checked {
  background-color: #003ea5;
  border-color: #003ea5;
}

.favoritesModal .table td .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.favoritesModal button.btn {
  height: 50px !important;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px !important;
  line-height: 100%;
  width: 100%;
  max-width: 100%;
}

.confirmationModal .modal-body {
  padding: 36px 50px 40px;
}

@media screen and (max-width: 600px) {
  .confirmationModal .modal-body {
    padding: 20px;
  }
}

.confirmationModal .modal-body h2 {
  margin-bottom: 10px;
}

.confirmationModal .modal-body p {
  margin-bottom: 20px;
}

.trash-link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border: 1px solid #f7665e;
  border-radius: 5px;
  background-color: #f7665e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.downloadMultipleModal .modal-dialog {
  max-width: 607px;
}

.downloadMultipleModal .modal-title {
  line-height: 100%;
}

.downloadMultipleModal .modal-header {
  padding-left: 37px;
  padding-right: 27px;
  padding-top: 18px;
  padding-bottom: 15px;
}

.downloadMultipleModal .modal-body {
  padding-left: 40px;
  padding-right: 40px;
}

.downloadMultipleModal .form-select {
  min-width: 242px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .downloadMultipleModal .form-select {
    min-width: auto;
  }
}

@media screen and (max-width: 600px) {
  .downloadMultipleModal .form-control {
    min-width: auto;
  }
}

.downloadMultipleModal .body-header .file-type {
  margin-left: 15px;
}

.downloadMultipleModal .body-header > p {
  margin-bottom: 20px;
}

.downloadMultipleModal .body-header p,
.downloadMultipleModal .body-header a {
  font-size: 12px;
  line-height: 100%;
}

.downloadMultipleModal .body-header a {
  font-weight: bold;
  text-decoration: none;
}

.downloadMultipleModal .body-header a:hover {
  cursor: pointer;
}

.downloadMultipleModal .table {
  padding: 0px;
}

.downloadMultipleModal .table td:first-child {
  padding-left: 0;
  width: 40%;
}

.downloadMultipleModal .table td:last-child {
  padding-right: 0;
}

.downloadMultipleModal .table .bg-w,
.downloadMultipleModal .table .bg-h {
  position: relative;
  background-position: 92% center;
  background-repeat: no-repeat;
  padding-right: 36px;
}

.downloadMultipleModal .table .bg-w {
  background-image: url("assets/img/icon-w.svg");
  background-size: 16px;
}

.downloadMultipleModal .table .bg-h {
  background-image: url("assets/img/icon-h.svg");
  background-size: 14px;
}

.downloadMultipleModal .img-area {
  max-height: 380px;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  //padding-left: 85px;
}

.downloadMultipleModal .img-areaVideo {
  max-height: 380px;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  padding-left: 60px;
}

.downloadMultipleModal .img-area img {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: block;
}

.downloadMultipleModal .img-areaVideo img {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: block;
}

.downloadMultipleModal button.btn {
  height: 50px !important;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px !important;
  line-height: 100%;
  width: 100%;
  max-width: 100%;
}

.downloadMultipleModal .asset-number {
  font-size: 12px;
  font-weight: 300;
  color: #202020;
}

.downloadMultipleModal .exampleSelect {
  margin-top: 14px;
  margin-bottom: 14px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 15px;
  column-gap: 15px;
}

.downloadMultipleModal .exampleSelect .exampleSelectCard {
  width: 58px;
  height: 72px;
  overflow: hidden;
}

.downloadMultipleModal .exampleSelect .exampleSelectCardVideo {
  width: 155px;
  height: 72px;
  overflow: hidden;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid #f5f6f8;
  position: relative;
  left: 20px;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mw-64 {
  max-width: 64px;
}

.mw-100 {
  max-width: 100px;
}

.nowrap {
  white-space: nowrap;
}

.modal-header {
  justify-content: space-between;
}

.assetDetailsModal .modal-dialog {
  max-width: inherit;
  margin: 0;
}

.assetDetailsModal .modal-dialog .modal-content {
  min-height: 100vh;
  border: 0;
}

.assetDetailsModal .modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid #f3f3f3;
  padding: 0px;
}

.assetDetailsModal .modal-dialog .modal-content .modal-header .modal-title {
  padding: 0.5rem 1rem 0.5rem 1rem;
  line-height: 100%;
  font-size: 16px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  button {
  background-repeat: no-repeat;
  width: 71px;
  height: 63px;
  background-color: white;
  border-right: 0px;
  border-bottom: 0px;
  border-top: 0px;
  border-left: 1px solid #f3f3f3;
}

@media screen and (max-width: 600px) {
  .assetDetailsModal
    .modal-dialog
    .modal-content
    .modal-header
    .asset-modal-header-buttons
    button {
    width: 51px;
  }
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  button.active {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border-bottom: 5px solid #003ea5;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  button:active,
.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-close {
  background-size: 22px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-download {
  background-image: url("assets/img/download.svg");
  background-position: center;
  background-size: 36px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-share {
  background-image: url("assets/img/share.svg");
  background-position: center;
  background-size: 25px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-favorite {
  background-image: url("assets/img/heart-no-fill.svg");
  background-position: center;
  background-size: 30px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-favorite-fill {
  background-image: url("assets/img/heart-fill.svg");
  background-position: center;
  background-size: 30px;
}

.assetDetailsModal
  .modal-dialog
  .modal-content
  .modal-header
  .asset-modal-header-buttons
  .btn-info {
  background-image: url("assets/img/info.svg");
  background-position: center;
  background-size: 26px;
}

.assetDetailsModal .modal-dialog .modal-content .modal-body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.assetDetailsModal .modal-dialog .modal-content .modal-body .asset-content {
  width: 100%;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.modal hr {
  border-top: 0;
  border-bottom: 1px solid #e6e6e6;
  opacity: 1;
}

.modal .btn-close {
  background: transparent url("assets/img/close.svg") center/1em auto no-repeat;
  opacity: 1;
  background-size: contain;
  margin: 0;
  padding: 0;
  width: 19px;
  height: 19px;
}

.modal input[type="text"] {
  height: 41px;
  padding: 0 12px;
  width: 100%;
  font-size: 14px;
}

.modal button {
  height: 40px;
  padding: 0 21px;
  font-size: 14px;
  display: inline-block;
  width: auto;
  line-height: 100%;
}

.exampleSelect {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  margin-bottom: 14px;
}

.exampleSelect .exampleSelectCard {
  width: 44px;
  height: 57px;
  display: block;
  border-radius: 2px;
}

@media screen and (max-width: 500px) {
  .exampleSelect .exampleSelectCard {
    width: 28px;
  }
}

.exampleSelect .exampleSelectCard.blue {
  background-color: #0d2658;
}

.exampleSelect .exampleSelectCard.gray {
  background-color: #d8d8d8;
}

.light-gray {
  color: #999999;
}

a.library-return-link {
  text-decoration: none;
  padding-left: 28px;
  margin-bottom: 40px;
  color: #202020;
  display: block;
  line-height: 100%;
  font-weight: 300;
  font-size: 16px;
}

div.files-shared {
  padding-left: 28px;
  margin-top: 20px;
  display: block;
}

div.files-shared p {
  font-size: 16px;
}

#main {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  padding: 0;
}

.sidenav {
  height: 100%;
  z-index: 1;
  top: 0;
  right: 0;
  position: relative;
  background-color: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  padding: 32px;
}

@media screen and (max-width: 600px) {
  .sidenav {
    padding: 20px;
  }
}

.sidenav button {
  height: inherit;
  padding: 20px 0px;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  line-height: 100%;
}

.sidenav button.accordion-button:not(.collapsed):focus,
.sidenav button.accordion-button:not(.collapsed) {
  background-color: white;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #212529;
}

.sidenav button.accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sidenav .accordion-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.sidenav .accordion-item {
  border: 0;
}

.sidenav .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidenav .accordion-button::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidenav .accordion-body {
  padding: 12px 0px;
}

.sidenav table td {
  border-bottom: 0;
  font-size: 14px;
}

.sidenav table td.title {
  color: #949494;
}

.sidenav table td.desc {
  color: #202020;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000 !important;
  text-transform: none;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
  text-transform: none;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000 !important;
  text-transform: none;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  text-transform: none;
}

::-ms-input-placeholder {
  color: #000 !important;
  text-transform: none;
}

::placeholder {
  color: #000 !important;
  text-transform: none;
}

.share-via-email-button {
  margin-top: 15px;
}

.fntblu {
  color: #003ea5 !important;
}
.minhitfit {
  min-height: fit-content !important;
}
.maxwth850px {
  max-width: 850px !important;
}
.hitfit {
  height: fit-content !important;
}
.txtalic {
  text-align: center !important;
}
.wth55px {
  width: 65px !important;
}
.wth105px {
  width: 125px !important;
}
.wth612px {
  width: 612px !important;
}
.padlft40p {
  padding-left: 41.5% !important;
}
.padlft45p {
  padding-left: 45% !important;
}
.fntweit500 {
  font-weight: 500 !important;
}
.pad35px53px {
  padding: 35px 53px !important;
}
.fnt25px {
  font-size: 25px !important;
}
.fnt17px {
  font-size: 17px !important;
}
.reset-password-heading {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 300;
  color: #8e8e8e;
  border-bottom: 1px solid #dfdfdf;
}

.green-alert {
  padding-top: 20px;
  width: 383px;
  height: 81px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-title {
  color: white;
  padding: 0;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.how-to-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
}

.steps-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5rem;
}

@media screen and (max-width: 767px) {
  .steps-container {
    display: table-row;
  }
}

.steps-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}

.step {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.step-title {
  font-size: 16px;
  margin: 0;
}

.step-description {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  padding-left: 20px;
  color: #000;
}

.step-description a {
  padding: 0;
}

.how-to-video {
  width: 100%;
  margin-top: 30px;
}

.video-title {
  font-size: 14px;
  margin-bottom: 20px;
}

.how-to-icon {
  width: 25px;
  height: 25px;
  margin: 0 5px;
}

.heart-icon {
  width: 40px;
  height: 40px;
}

.step-option {
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 0;
  color: #c2c3c3;
  border-bottom: 1px solid #e1e1e1;
}

.howto {
  width: 25px;
  height: 25px;
}

.maintenance-warning {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #a50002;
}

.warning-text {
  font-size: 16px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-wrap: wrap;
  color: #fff;
}

.warning-bold {
  font-weight: bold;
}

// Download Container

.loaderContainer {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  row-gap: 1rem;
}

.downloadContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 3rem;
  padding-bottom: 1rem;
}

.downloadContainerTopbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.topbarButtons {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbarButton {
  text-wrap: nowrap;
  padding: 0.5rem 1rem;
}

.refreshIconSM {
  width: 1rem;
  height: 1rem;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

//Download Card

.downloadCard {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0px 6px 2px 0px #0000001a;
  padding: 10px 20px;
  position: relative;
}

.cardMessageContainer {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cardNameMessage {
  font-size: 7px;
  font-weight: 500;
  text-align: left;
  color: #828282;
}

.downloadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: auto;
  margin-left: 1rem;
  align-self: center;
}

.documentIcon {
  width: 2.75rem;
  height: auto;
}

.downloadCardBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 30px;
}

.downloadCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.cardTitle {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.cardExpiration {
  font-size: 12px;
  font-weight: 500;
  color: #a9a9a9;
  margin: 0;
}

.downloadButtonContainer {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
}

// Buttons

.downloadDynamicButton {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1.5px solid #003ea5;
  border-radius: 2px;
  padding: 1.2rem;
}

.downloadDynamicButtonSM {
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 1.5px solid #003ea5;
  border-radius: 2px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: right;
}
